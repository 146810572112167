import moment from 'moment';

const TimeToolsMixin = {
  methods: {
    /**
     *  Computes a timedelta for two given times. Returns a specifically formatted string
     *  currently used in the Skillsgap templates
     * @param start_time
     * @param end_time
     * @returns {string}
     */
    computeDuration: function(start_time, end_time) {
      let dur = moment(end_time).diff(start_time);
      let time_segments = [
        moment.duration(dur).get('hours') || '00',
        moment.duration(dur).get('minutes') || '00',
        moment.duration(dur).get('seconds') || '00'
      ];

      // Force a double digit answer
      for (let [key, value] of Object.entries(time_segments)) {
        if (String(value).length === 1) {
          time_segments[key] = '0' + String(value);
        }
      }

      let total_duration = time_segments.join(':');
      return total_duration === '00:00:00' ? '': total_duration;
    }
  },
};

export default TimeToolsMixin;

<template>
  <div class="sp-banner">
    <b-container>
      <b-row>
        <b-col class="text-center p-5 banner">
          <b-row class="m-2">
            <b-col cols="12" md="8" offset-md="2"
                   class="text-center">
              <b-row>
                <b-col class="m-2">
                  <h3 v-if="bannerData.title">
                    {{ bannerData.title }}
                  </h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="m-2">
                  <p v-if="bannerData.text">
                    {{ bannerData.text }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="bannerData.button" class="text-center">
            <b-col class="text-center">
              <b-button
                variant="primary"
                class="px-3"
                @click="openLink(bannerData.button.href)">
                {{ bannerData.button.text }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'Banner',
  props: {
    'bannerData': {
      type: Object,
      default: null
    }
  },
  data() {
    return {};
  },
  methods: {
    /**
     * Open an external URL -- outside of the Vue project
     * Will find and open a named URL if the `.named` value is set to true (and the `extURLData.url` is a named route)
     * @param extURL
     */
    openLink(extURLData) {
      if (extURLData.named) {
        window.open(this.$router.resolve({name: extURLData.url}).href, '_blank');
      } else {
        window.open(extURLData, '_blank');
      }
    }
  }
};
</script>
<style lang="scss">
  .sp-banner {
    .banner {
      background-color: #00295B;
    }
    .container {
      max-width: 100%;
    }
    h3 {
      font-family: Open Sans,sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;

      color: #F2F2F2;
    }
    p {
      font-family: Open Sans,sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: #FFFFFF;
    }
  }
</style>
